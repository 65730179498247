import React from 'react'
import './services.css'
import { BiCheck } from 'react-icons/bi'


const Services = () => {
  return (
    <section id='services'>
      <h5 data-aos="fade-up" data-aos-anchor="#services"  data-aos-duration='700'>The things i can do for you</h5>
      <h2 data-aos="fade-up" data-aos-anchor="#services"  data-aos-duration='900'>My Services</h2>
      
      <div className="container services__container">
        <article className="service" data-aos="fade-right" data-aos-anchor=".services__container"  data-aos-duration="900">

          <div className="service__head">
            <h3>Shopify Development</h3>
          </div>
 
          <ul className="service__list">
             <li>
                <BiCheck className='service__list-icon'/>
                <p>Hands-on experience with Shopify product, collection, order, customer API.</p>
             </li>
             <li>
                <BiCheck className='service__list-icon'/>
                <p>Shopify Template Development.</p>
             </li>
             <li>
                <BiCheck className='service__list-icon'/>
                <p>Customization Shopify Store with liquid.</p>
             </li>
             <li>
                <BiCheck className='service__list-icon'/>
                <p>Audit & optimization</p>
             </li>
             <li>
                <BiCheck className='service__list-icon'/>
               <p>Shopify Theme Development</p>
             </li>
             <li>
                <BiCheck className='service__list-icon'/>
               <p>Shopify App Development</p>
             </li>
             <li>
                <BiCheck className='service__list-icon'/>
               <p>system/API integrations.</p>
             </li>
             <li>
                <BiCheck className='service__list-icon'/>
               <p>Deep understanding in several eCommerce platforms such as Wordpress, magento and features, especially Shopify</p>
             </li>
          </ul>
        </article>

        {/* END OF UI/UX */}
        

        <article className="service" data-aos="fade-up" data-aos-anchor=".services__container"  data-aos-duration="1200">

          <div className="service__head">
            <h3>Web development</h3>
          </div>

          <ul className="service__list">
            <li>
                <BiCheck className='service__list-icon'/>
                <p>Designing and building websites and web applications using best practices.</p>
            </li>
            <li>
                <BiCheck className='service__list-icon'/>
                <p>Developing responsive, mobile-friendly websites that work on various devices.</p>
            </li>
            <li>
                <BiCheck className='service__list-icon'/>
                <p>Implementing features and functionality using server-side languages</p>
            </li>
            <li>
                <BiCheck className='service__list-icon'/>
                <p>Integrating websites and applications with databases and APIs.</p>
            </li>

            {/* <li>
                <BiCheck className='service__list-icon'/>
                <p>Setting up and configuring servers and hosting environments</p>
            </li> */}
            <li>
                <BiCheck className='service__list-icon'/>
                <p>Testing and debugging websites and applications to ensure functionality</p>
            </li>
            <li>
                <BiCheck className='service__list-icon'/>
                <p>Providing technical support and assistance to website users and clients.</p>
            </li>
          </ul>
        </article>

        {/* End of web development  */}


        <article className="service" data-aos="fade-left" data-aos-anchor=".services__container"  data-aos-duration="900">

          <div className="service__head">
            <h3>AI/Machine Learning</h3>
          </div>

          <ul className="service__list">
              <li>
                  <BiCheck className='service__list-icon'/>
                 <p>Machine Learning Model Development: Building and tuning models using TensorFlow, Scikit-Learn, and other advanced tools.</p>
              </li>
              <li>
                  <BiCheck className='service__list-icon'/>
                 <p>Open-Source AI Integration for Process Automation: OpenAI API, ChatGPT, custom GPTs, open-source LLMs (Llama-2, Falcon, etc), you name it!.</p>
              </li>
              <li>
                  <BiCheck className='service__list-icon'/>
                  <p>Data Analysis and Predictive Analytics: Using Python, R, and SQL for insightful data analysis and predictive modeling.</p>
              </li>
              <li>
                  <BiCheck className='service__list-icon'/>
                 <p> Project-Based AI Solutions: Tailoring AI strategies and implementations to specific project needs and goals.</p>
              </li>
              {/* <li>
                  <BiCheck className='service__list-icon'/>
                 <p> Building and maintaining relationships with customers and clients.</p>
              </li>
              <li>
                  <BiCheck className='service__list-icon'/>
                 <p>Developing and implementing digital marketing strategies.</p> 
              </li> */}
            </ul>
          </article>

          {/* End of digital marketting  */}



      </div>
    </section>
  )
}

export default Services